<template>
  <div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
          <div class="text-right list-unstyled">
            <locale />
          </div>

          <b-link class="brand-logo">
            <b-img
              :src="appLogoImage"
              style="max-height: 100px"
              alt="logo"
            />
          </b-link>

          <!--        <b-card-title-->
          <!--            class="mb-1 font-weight-bold text-center"-->
          <!--            title-tag="h2"-->
          <!--        >-->
          <!--          {{ $t('login_page.card_title') }}-->
          <!--        </b-card-title>-->
          <!-- divider -->
          <!--        <div class="divider my-2">-->
          <!--          <div class="divider-text"></div>-->
          <!--        </div>-->
          <b-card-text class="mb-2">
            {{ $t('login_page.card_text') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group>
                <label for="login-email"><span class="text-danger text-bold">*</span> {{ $t('login_page.lbl_email') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false: userEmail != null ? true : null"
                    name="login-email"
                    placeholder="alikaplan@geribildir.io"
                  />
                  <small v-if="errors[0]" class="text-danger">{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password"><span class="text-danger text-bold">*</span> {{ $t('login_page.lbl_password') }}</label>

                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>{{ $t('login_page.forgot_password') }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false: password != null ? true : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('login_page.password_placeholder')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t('login_page.btn_login') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card class="mt-1 text-center">
          {{ $t('login_page.dont_you_have_an_account') }} <b-link :to="{name:'auth-register'}">
            {{ $t('login_page.btn_register') }}
          </b-link>
        </b-card>
      <!-- /Login v1 -->
      </div>
    </div>

    <!-- Footer -->
    <footer
      class="footer footer-light"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import { canNavigate } from '@/libs/acl/routeProtection'
import ability from '@/libs/acl/ability'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    AppFooter,
    BCard,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: null,
      userEmail: null,
      // sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg: require('@/assets/images/pages/login/solar-panel.gif'),

      // validation rules
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.loginUser({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data
              useJwt.setToken(response.data.access)
              useJwt.setRefreshToken(response.data.refresh)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)
              let routerPath = null
              if (userData.role === 'client') {
                if (this.$ability.can('read', 'clientregister')) {
                  //  ilgili sayfaya gidecek yoksa plan selecte gidecek
                  routerPath = { name: 'auth-register-planselect' }
                } else routerPath = { name: 'dashboard' }
              } else {
                routerPath = getHomeRouteForLoggedInUser(userData.role)
              }
              this.$router.replace(routerPath)
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.$t('login_page.mgs_welcome')} ${userData.name}`,
                      icon: 'CoffeeIcon',
                      variant: 'info',
                    },
                  })
                })
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
